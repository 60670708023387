import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import JotFormReact from "jotform-react"

const ContactPage = () => {
  const GMaps =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2608.4426823843114!2d-122.89890054877236!3d49.173185886342445!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485d9aad67d18bd%3A0xb8efddf043eca560!2s11762%2094%20Ave%2C%20Delta%2C%20BC%20V4C%203R7!5e0!3m2!1sen!2sca!4v1610392275255!5m2!1sen!2sca"

  const GStyle = {
    border: `0`,
  }

  return (
    <Layout>
      <Seo
        title="Contact Garden Escape Landscaping"
        description="get 25% off your first mow!"
        canonical="https://gardenescape.ca/contact/"
      />

      <div className="flex h-[400px] w-full p-0 my-8 overflow-hidden">
        <iframe
          className="grow"
          title="Garden Escape"
          src={GMaps}
          width="600"
          height="450"
          frameBorder="0"
          style={GStyle}
          allowFullScreen=""
          aria-hidden="false"
          disable="geolocation; microphone; camera"
        ></iframe>
      </div>

      <div className="mainWrapper mb-8">
        <h1 className="text-center">
          Contact
          <br />
          Garden Escape
        </h1>
        <p className="text-center">
          11762 94 Ave.
          <br />
          Delta, BC, V4C 3R7
        </p>
        <p className="text-center">778-589-5477</p>
      </div>

      <div className="mainWrapper">
        <JotFormReact
          formURL="https://form.jotform.com/210105570484246"
          autoResize={true}
        />
      </div>
    </Layout>
  )
}

export default ContactPage
